@use '@angular/material' as mat;
@import "../node_modules/bootstrap/scss/bootstrap";
@import '~video.js/dist/video-js.css';

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming

$cim-primary-palette: (
  50 : #fef0e7,
  100 : #fedac2,
  200 : #fdc19a,
  300 : #fca871,
  400 : #fb9652,
  500 : #fa8334,
  600 : #f97b2f,
  700 : #f97027,
  800 : #f86621,
  900 : #f65315,
  A100 : #ffffff,
  A200 : #fff5f2,
  A400 : #ffcfbf,
  A700 : #ffbba6,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$cim-secondary-palette: (
  50 : #e2e5ee,
  100 : #b6bdd5,
  200 : #8592b9,
  300 : #54669c,
  400 : #2f4587,
  500 : #0a2472,
  600 : #09206a,
  700 : #071b5f,
  800 : #051655,
  900 : #030d42,
  A100 : #7785ff,
  A200 : #4457ff,
  A400 : #1129ff,
  A700 : #0019f6,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$cim-warning-palette: (
  50 : #ffeaea,
  100 : #ffcaca,
  200 : #ffa6a6,
  300 : #ff8282,
  400 : #ff6868,
  500 : #ff4d4d,
  600 : #ff4646,
  700 : #ff3d3d,
  800 : #ff3434,
  900 : #ff2525,
  A100 : #ffffff,
  A200 : #ffffff,
  A400 : #ffd3d3,
  A700 : #ffb9b9,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$cim-notification-palette: (
  50 : #fff8e0,
  100 : #ffeeb3,
  200 : #ffe380,
  300 : #ffd84d,
  400 : #ffcf26,
  500 : #ffc700,
  600 : #ffc100,
  700 : #ffba00,
  800 : #ffb300,
  900 : #ffa600,
  A100 : #ffffff,
  A200 : #fffaf2,
  A400 : #ffe6bf,
  A700 : #ffdca6,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$cim-success-palette: (
  50 : #eff6e3,
  100 : #d8e9ba,
  200 : #beda8c,
  300 : #a3cb5d,
  400 : #90c03b,
  500 : #7cb518,
  600 : #74ae15,
  700 : #69a511,
  800 : #5f9d0e,
  900 : #4c8d08,
  A100 : #daffbb,
  A200 : #bfff88,
  A400 : #a3ff55,
  A700 : #95ff3b,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$cim-grey-palette: (
  50 : #f4f4f4,
  100 : #e3e3e3,
  200 : #d0d0d0,
  300 : #bdbdbd,
  400 : #aeaeae,
  500 : #a0a0a0,
  600 : #989898,
  700 : #8e8e8e,
  800 : #848484,
  900 : #737373,
  A100 : #fef7f7,
  A200 : #f9c9c9,
  A400 : #ff9090,
  A700 : #ff7777,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$cim-accent-palette: (
  50 : #e5e4e3,
  100 : #bebbb9,
  200 : #938e8a,
  300 : #67605b,
  400 : #473e38,
  500 : #261c15,
  600 : #221912,
  700 : #1c140f,
  800 : #17110c,
  900 : #0d0906,
  A100 : #ffa452,
  A200 : #ff8a1f,
  A400 : #eb7000,
  A700 : #d16400,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #ffffff,
  )
);

@include mat.core();

$cim-primary: mat.define-palette($cim-primary-palette, 500);
$cim-accent: mat.define-palette($cim-secondary-palette, 500);
$cim-warning: mat.define-palette($cim-warning-palette, 500);
$cim-notification: mat.define-palette($cim-notification-palette, 500);
$cim-success: mat.define-palette($cim-success-palette, 500);
$cim-grey: mat.define-palette($cim-grey-palette, 500);

$cim-typography: mat.define-typography-config(
  $font-family: 'Montserrat, sans-serif',
  $body-1: mat.define-typography-level(
      $font-size: 16px,
      $font-family: 'Montserrat, sans-serif',
      $font-weight: 300,
    ),
  $caption: mat.define-typography-level(
      $font-size: 12px,
      $font-family: 'Montserrat, sans-serif',
      $font-weight: 300,
    ),
  // h1
  $headline-5: mat.define-typography-level(
      $font-size: 40px,
      $font-family: 'Montserrat, sans-serif',
      $font-weight: 600,
    ),
  // h2
  $headline-6: mat.define-typography-level(
      $font-size: 24px,
      $font-family: 'Montserrat, sans-serif',
      $font-weight: 600,
    ),
  // h3
  $subtitle-1: mat.define-typography-level(
      $font-size: 20px,
      $font-family: 'Montserrat, sans-serif',
      $font-weight: 600,
    ),
  $button: mat.define-typography-level(
      $font-size: 16px,
      $font-family: 'Montserrat, sans-serif',
      $font-weight: 700,
    ),
);

$cim-theme: mat.define-light-theme((
  color: (
    primary: $cim-primary,
    accent: $cim-accent,
    warn: $cim-warning,
    notification: $cim-notification,
    success: $cim-success,
    grey: $cim-grey,
  ),
  typography: $cim-typography,
));

@include mat.core-theme($cim-theme);
@include mat.all-component-colors($cim-theme);
@include mat.all-component-typographies($cim-typography);

@mixin transitions($time) {
  -webkit-transition: all $time ease-in-out;
  -moz-transition: all $time ease-in-out;
  -o-transition: all $time ease-in-out;
  transition: all $time ease-in-out;
}

@mixin mat-icon-size($size) {
  font-size: $size;
  height: $size;
  width: $size;
  line-height: $size;
}

/* You can add global styles to this file, and also import other style files */
mat-form-field {
  .mat-mdc-text-field-wrapper {
    padding: 12px 16px;
    border-radius: 8px;
    border: 1px solid #D0D0D0;

    &:hover {
      border: 1px solid mat.get-theme-color($cim-theme, primary, 200);
    }

    &.mdc-text-field--focused {
      border: 1px solid mat.get-theme-color($cim-theme, primary, 500);
    }


    &.mdc-text-field--invalid {
      border: 1px solid mat.get-theme-color($cim-theme, warn, 500);
    }

    &.mdc-text-field--filled:not(.mdc-text-field--disabled) {
      background-color: #FFF;
    }

    &.mdc-text-field--filled.mdc-text-field--disabled {
      background-color: #F2F3F4;
    }

    .mat-mdc-form-field-focus-overlay {
      display: none;
    }
    .mdc-line-ripple {
      display: none;
    }

    .mat-mdc-form-field-infix {
      height: 35px;
    }

    label {
      top: 17px !important;

      mat-label {
        line-height: 22px;
      }
    }

    input, mat-select {
      margin-top: 15px;
    }
  }

  .mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align {
    min-height: 18px;
    margin-bottom: 5px;

    .mat-mdc-form-field-hint-wrapper {
      position: inherit;
      margin-top: -20px;
    }
  }
}

button, button.mdc-button {
  font-size: 16px;
  font-weight: 700;
  border-radius: 40px !important;
  min-width: 36px;

  &.mat-mdc-raised-button, &.mat-mdc-raised-button.mat-primary, &.mat-mdc-raised-button.mat-accent,
  &.mat-mdc-unelevated-button, &.mat-mdc-unelevated-button.mat-primary, &.mat-mdc-unelevated-button.mat-accent,
  &.mdc-button--outlined, &.mat-mdc-outlined-button, &mat-mdc-button-base, &:not(.mat-mdc-icon-button):not(.mat-mdc-snack-bar-action).mat-mdc-button-base {
    padding: 0 24px !important;
    line-height: 40px !important;
    min-width: 110px;

    img {
      margin-top: -3px;
    }
  }

  &.mat-mdc-raised-button, &.mat-mdc-raised-button.mat-primary, &.mat-mdc-raised-button.mat-accent,
  &.mat-mdc-unelevated-button, &.mat-mdc-unelevated-button.mat-primary, &.mat-mdc-unelevated-button.mat-accent {
    color: white;
  }

  &.mdc-icon-button.mat-mdc-icon-button {
    display: flex;
    align-items: center;
    padding: 12px;

    mat-icon, img {
      width: 24px;
      height: 24px;
      font-size: 24px;
    }
  }
}

mat-paginator.mat-mdc-paginator {
  mat-form-field {
    .mat-mdc-text-field-wrapper {
      padding: 5px 15px;

      mat-select.mat-mdc-select {
        margin: 0;
        height: fit-content;
      }
    }
  }
}

mat-card.mat-mdc-card.mdc-card {
  min-height: 56px;
  padding: 16px;
  background-color: #F2F3F4;
  box-shadow: none;
  border-radius: 8px;

  &.loading {
    animation-name: fade;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }

  @keyframes fade {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.2;
    }
    100% {
      opacity: 1;
    }
  }

  mat-card-header {
    height: 40px;
    margin-top: -8px;
    margin-bottom: 16px;
    padding: 0;
    display: flex;
    align-items: center;
    align-self: stretch;
    color: #261C15;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
  }

  mat-card-content {
    padding: 0;
  }
}

.cdk-overlay-container {
  .cdk-global-overlay-wrapper {
    mat-snack-bar-container {
      & > div {
        border-radius: 8px;

        .mat-mdc-snack-bar-label.mdc-snackbar__label {
          font-size: 16px;
        }
      }

      &.error > div {
        background-color: mat.get-theme-color($cim-theme, warn, 500);
        color: #FFF;
      }
    }
  }
}

mat-slide-toggle {
  .mdc-form-field {
    button {
      background-color: transparent;

      .mdc-switch__track {
        height: 18px;
        border-radius: 16px;

        &::before, &::after {
          background-color: transparent !important;
          border: 2px solid mat.get-theme-color($cim-theme, warn, 500);
        }
      }

      .mdc-switch__handle {
        width: 10px !important;
        height: 10px !important;
        left: 4px !important;
        margin-left: 2px;

        &::after {
          background-color: mat.get-theme-color($cim-theme, warn, 500) !important;
        }

        .mdc-switch__icons{
          svg{
            display: none;
          }
        }

      }
    }
  }

  &.mat-mdc-slide-toggle-checked {
    .mdc-form-field {
      button {
        .mdc-switch__track {
          &::before, &::after {
            background-color: transparent !important;
            border: 2px solid #7cb518;
          }
        }

        .mdc-switch__handle {;
          &::after {
            background-color: #7cb518 !important;
          }
        }
      }
    }
  }
}

mat-dialog-container.mat-mdc-dialog-container {
  min-width: 300px;
  min-height: 175px;
  max-height: 90vh;

  .mat-mdc-dialog-surface {
    overflow-y: visible;
  }
}

.alert {
  &.alert-warning {
    background-color: #fff8e0;
  }
}
